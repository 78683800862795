  .rtl{
    direction: rtl;
    text-align: right;}
  div.detailsScreenModal {
  padding-bottom: 60px;
  div.imgVideoContainer {
    // width: 779px;
    width: 100%;
    position: relative;
    overflow: hidden;
    // min-width: 779px;
    height: 450px;
    @media all and (max-width:640px){
      height: 326px;
    }
    @media all and (max-width:480px){
      height: 252px;
    }
    &.noHeight{
      height: unset;
      @media all and (max-width:640px){
        div.bottomVignette {
            bottom: -31px;
        }
      }
    }
    video{
      z-index: 3;
    }
    img {
      display: block;
      width: 100%;
      position: absolute;
      z-index: 4;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      &.show{
        display: block;
      }
      &.hide{
        display: none;
      }
    }
    div.closeButton{
      position: absolute;
      inset-inline-end: 10px;
      top: 10px;
      z-index: 9999;
      button{
        background-color: var(--bg-color);
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        line-height: 0;
        cursor: pointer;
        svg{
          stroke: var(--white-color) !important;
        }
      }
    }
    
    div.bottomVignette {
      background-color: transparent;
      background-image: linear-gradient(
        180deg,
        hsla(0, 0%, 8%, 0) 0,
        hsla(0, 0%, 30%, 0.15) 15%,
        hsla(0, 0%, 8%, 0.35) 29%,
        hsla(0, 0%, 8%, 0.58) 40%,
        #141414 84%,
        #141414
      );
      background-position: 0 top;
      background-repeat: repeat-x;
      background-size: 100% 100%;
      bottom: -8px;
      height: 200px;
      opacity: 1;
      top: auto;
      width: 100%;
      z-index: 99;
      position: absolute;
      @media all and (max-width:640px){
        bottom: 31px;
      }
    }
    div.showActions {
      position: absolute;
      bottom: 40px;
      z-index: 999;
      width: 100%;
      h1.title {
        padding: 0 40px;
        animation: fadeIn 0.4s ease-in-out;
        font-size: 60px;
        z-index: 99;
        font-family: "Gagalin-Regular", sans-serif !important;
        color: var(--white-color);
        max-width: 80%;
        margin-bottom: 20px;
        @media all and (max-width:640px){
          font-size: 40px;
          padding: 0 18px;
        }
        @media all and (max-width:480px){
          font-size: 21px;
          margin-bottom: 6px;
        }
      }
      div.buttons {
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width:640px){
          padding: 0 18px;
        }
        div.left{
          display: flex;
          align-items: center;
          button.play {
            display: flex;
            padding: 6px 1px;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            width: 7.5rem;
            background-color: var(--primary-btn-color);
            margin-inline-end: 10px;
            &:hover {
              background-color: var(--primary-btn-hover);
            }
            &:focus {
              border: 1px solid var(--white-color);
            }
            @media all and (max-width:480px){
              width: 5.5rem;
              
            }
            div.icon {
              margin-inline-end: 10px;
              line-height: 0;
              svg {
                width: 25px !important;
                height: 25px !important;
              color: var(--black-color);
                display: block;
                @media all and (max-width:480px){
                  width: 16px !important;
                  height: 16px !important;
                }
              }
              img {
                width: 100%;
                display: block;
              }
            }
            span {
              font-size: 16px;
              font-weight: 500;
              color: var(--black-color);
              @media all and (max-width:480px){
                font-size: 12px;
              }
            }
          }
          button.subscribe{
            width: 8rem;
            padding: 6px 1px 9px;
            border-radius: 2px;
            background-color: var(--secondary-color);
            color: var(--white-color);
            margin-inline-end: 10px;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            &:hover{
              background-color: var(--secondary-hover-color);
            }
            span{
              font-size: 14px;
            }
  
          }
          button.watchnow{
            display: flex;
            padding: 6px 1px;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            width: 8.5rem;
            background-color: var(--primary-btn-color);
            margin-inline-end: 10px;
            &:hover {
              background-color: var(--primary-btn-hover);
            }
            &:focus {
              border: 1px solid var(--white-color);
            }
            @media all and (max-width:480px){
              width: 6.5rem;
              
            }
            div.icon {
              margin-inline-end: 10px;
              line-height: 0;
              svg {
                width: 25px !important;
                height: 25px !important;
              color: var(--black-color);
                display: block;
                @media all and (max-width:480px){
                  width: 16px !important;
                  height: 16px !important;
                }
              }
              img {
                width: 100%;
                display: block;
              }
            }
            span {
              font-size: 16px;
              font-weight: 500;
              color: var(--black-color);
              @media all and (max-width:480px){
                font-size: 12px;
              }
            }
          }
          button.addToList {
            background-color: rgba(42, 42, 42, 0.6);
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid hsla(0, 0%, 100%, 0.5);
            cursor: pointer;
            color: var(--white-color);
            transition: 0.2s ease-in-out;
            position: relative;
            svg {
              width: 20px !important;
              height: 20px !important;
            }
            &:hover {
              background-color: var(--secondary-btn-hover);
              border-color: var(--white-color);
              span.tooltip {
                display: flex;
                animation: fadeIn 0.3s ease-in-out;
              }
            }
            span.tooltip {
              display: none;
              justify-content: center;
              align-items: center;
              background-color: #d6d5d5;
              position: absolute;
              top: -50px;
              width: 10rem;
              padding: 6px 8px 6px;
              color: var(--bg-color);
              font-weight: 500;
              border-radius: 2px;
              font-size: 16px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              &.remove{
                width: 12rem;
              }
              &::after {
                content: "";
                position: absolute;
                top: 99%;
                inset-inline-start: 50%;
                margin-inline-start: -5px;
                border-width: 6px;
                border-style: solid;
                border-color: #d6d5d5 transparent transparent transparent;
              }
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
          }
          div.share{
            position: relative;
            button.shareButton{
              background-color: rgba(42, 42, 42, 0.6);
              width: 35px;
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              border: 1px solid hsla(0, 0%, 100%, 0.5);
              cursor: pointer;
              color: var(--white-color);
              transition: 0.2s ease-in-out;
              position: relative;
              margin-inline-start: 10px;
              svg {
                width: 20px !important;
                height: 20px !important;
              }
              &:hover {
                background-color: var(--secondary-btn-hover);
                border-color: var(--white-color);
               
              }
            }
            div.shareContainer {
              position: absolute;
              top: -126px;
              inset-inline-start: -74px;
              z-index: 999;
              background-color: var(--bg-color);
              border: 1px solid var(--border-color);
              width: 232px;
              border-radius: 8px;
              display: none;
              @media all and (max-width: 2500px) {
                inset-inline-start:-89px;
              }
              @media all and (max-width: 1200px) {
                width: 200px;
                inset-inline-start: -74px;
              }
              @media all and (max-width: 980px) {
                width: 232px;
                left: 50%;
                transform: translateX(-50%);
              }
              @media all and (max-width: 480px) {
                width: 11rem;
              }
              &.active {
                display: block;
              }
              &::after {
                content: "";
                bottom: -8px;
                width: 0;
                height: 0;
                border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);

                border-style: solid;
                border-width: 0 8px 8px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%) rotate(180deg);
               
                @media all and (max-width: 980px) {
                  left: 50%;
                  
                }
                @media all and (max-width: 768px) {
                  border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
                }
              }
              div.facebookContainer {
                // display: flex;
                // justify-content: space-between;
                // padding: 15px;
                // color: var(--text-color);
                // cursor: pointer;
                // border-bottom: 1px solid var(--border-color);
                button.facebook {
                  display: flex !important;
                  justify-content: space-between !important;
                  padding: 15px !important;
                  color: var(--text-color) !important;
                  cursor: pointer !important;
                  border-bottom: 1px solid var(--border-color) !important;
                  width: 100% !important;
                  div.icon {
                    width: 10%;
                    svg {
                      width: 20px !important;
                      height: 20px !important;
                      color: var(--grey-color);
                      transition: 0.3s ease-in-out;
                      &:hover {
                        color: var(--text-color);
                      }
                    }
                  }
                }
                div.left {
                  width: 10%;
                }
                div.right {
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    color: var(--grey-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      color: var(--text-color);
                    }
                  }
                }
              }
              div.twitterContainer {
                // display: flex;
                // justify-content: space-between;
                // padding: 15px;
                // color: var(--text-color);
    
                // cursor: pointer;
                button.twitter {
                  display: flex !important;
                  justify-content: space-between !important;
                  padding: 15px !important;
                  color: var(--text-color) !important;
                  cursor: pointer !important;
                  // border-bottom: 1px solid var(--border-color) !important;
                  width: 100% !important;
                  div.icon {
                    width: 10%;
                    svg {
                      width: 20px !important;
                      height: 20px !important;
                      color: var(--grey-color);
                      transition: 0.3s ease-in-out;
                      &:hover {
                        color: var(--text-color);
                      }
                    }
                  }
                }
                div.left {
                  width: 10%;
                }
                div.right {
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    color: var(--grey-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      color: var(--text-color);
                    }
                  }
                }
              }
            }

          }
        }
        div.right{
          div.volumeButton{
            button{
              background-color: rgba(42, 42, 42, 0.6);
                width: 38px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: .12em solid hsla(0, 0%, 100%, 0.5);
                cursor: pointer;
                color: var(--white-color);
                transition: 0.2s ease-in-out;
                position: relative;
                opacity: 0.3;
                &:hover{
                  opacity: 1;
                  background-color: var(--secondary-btn-hover);
                  border-color: var(--white-color);
                  svg{
                    color: var(--white-color);
                  }
                }
                svg {
                transition: 0.2s ease-in-out;
      
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                }
            }
          }
          div.userRatings{
            display: flex;
            margin-inline-start: 15px;
            @media all and (max-width:360px){
              margin-inline-start: 10px;
            }
            div.star{
              &.active{
                svg{
                  fill: #fbe725;
                }
              }
              svg {
                width: 23px !important;
                height: 23px !important;
                fill: var(--grey-color);
                transition: 0.3s ease-in-out;
                @media (max-width: 360px){
                  width: 15px !important;
                  height: 15px !important;
                }
              }
            }
           
          }
          div.addRating{
            margin-inline-start: 15px;

            cursor: pointer;
            &:hover{
              svg{
                fill: var(--grey-color);
              }
            }
            svg {
              width: 27px !important;
              height: 27px !important;
              fill: var(--white-color);
              transition: 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
  div.info {
    padding: 0 40px;
    margin-top: 5px;
    @media all and (max-width:640px){
      padding: 0 18px;
    }
    div.metaData {
      display: flex;
      justify-content: space-between;
      @media all and (max-width:640px){
        flex-direction: column;
      }
      div.left {
        width: 60%;
        @media all and (max-width:640px){
          width: 100%;
          margin-bottom: 20px;
        }
        div.top {
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            margin-inline-end: 10px;
          }
          span.duration {
            color: var(--duration-color);
            direction: ltr !important;
            unicode-bidi: embed !important;
          }
          span.rating {
            padding: 1px 6px;
            border: 1px solid var(--border-color);
          }
          div.audio {
            font-size: 14px;
            position: relative;
            &:hover {
              span.tooltip {
                display: flex;
                animation: fadeIn 0.3s ease-in-out;
              }
            }
            span.tooltip {
              display: none;
              justify-content: center;
              align-items: center;
              background-color: #d6d5d5;
              position: absolute;
              top: -41px;
              width: 145px;
              padding: 6px 8px 6px;
              color: var(--bg-color);
              font-weight: 500;
              border-radius: 2px;
              font-size: 16px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              z-index: 9;
              inset-inline-start: -40px;
              &::after {
                content: "";
                position: absolute;
                top: 99%;
                inset-inline-start: 50%;
                margin-inline-start: -5px;
                border-width: 6px;
                border-style: solid;
                border-color: #d6d5d5 transparent transparent transparent;
              }
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
          }
        }
        div.bottom {
          margin-top: 30px;

          p.description {
            display: flex;
            font-size: 14px;
          }
          div.subscriptionContainer{
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;
            button{
              margin-inline-end: 6px;
              margin-bottom: 6px;
              padding: 6px 8px;
              background-color: var(--secondary-color);
              display: block;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              &:hover{
                background-color: var(--secondary-hover-color);
              }
              &.free{
                background-color: var(--freeBtn-color);
                &:hover{
                background-color: var(--freeBtn-hover);

                }
                span{
                  color: var(--white-color);
                }
              }
              span{
                color: var(--white-color);
              font-size: 12px;
              display: block;
              }
              &:last-child{
                margin-inline-end: 0;
              }
            }
          }
        }
      }
      div.right {
        width: 35%;
        @media all and (max-width:640px){
          width: 100%;
        }
        span.heading {
          font-size: 12px;
          color: var(--grey-color);
          margin-inline-end: 3px;
        }
        div.cast,
        div.crew,
        div.categories,div.director {
          span {
            font-size: 13px;
            margin-inline-end: 4px;
          }
          margin-bottom: 10px;
        }
        :last-child {
          margin-bottom: 0;
        }
      }
    }
    div.categories {
      padding: 30px 0px ;
      background-color: transparent !important;
      @media all and (max-width: 768px) {
        padding: 30px 30px;
      }
      h1.heading {
        font-size: 25px;
        color: var(--text-color);
        margin-bottom: 20px;
        text-transform: capitalize;
        display: flex;
        inset-inline-start: 0;
      }
      div.categoryLists {
        ul {
          display: flex;
          flex-wrap: wrap;
          li.list {
            display: flex;
            margin-inline-end: 10px;
            width: 24%;
            background-color: var(--bg-color);
            margin-bottom: 10px;
            cursor: pointer;
  
            &:nth-child(4n) {
              margin-inline-end: 0;
            }
            @media all and (max-width: 1200px) {
              width: 32.6%;
              &:nth-child(3n) {
                margin-inline-end: 0;
              }
              &:nth-child(4n) {
                margin-inline-end: 10px;
              }
            }
            @media all and (max-width: 980px) {
              width: 49%;
              &:nth-child(3n) {
                margin-inline-end: 10px;
              }
              &:nth-child(2n) {
                margin-inline-end: 0;
              }
            }
            @media all and (max-width: 640px) {
              width: 100%;
              &:nth-child(3n) {
                margin-inline-end: 0;
              }
              &:nth-child(2n) {
                margin-inline-end: 0;
              }
              margin-inline-end: 0;
            }
            div.left {
              div.imageContainer {
                width: 70px;
                img {
                  display: block;
                  width: 100%;
                }
              }
            }
            div.right {
              padding: 10px;
              h1.title {
                font-size: 14px;
                // text-transform: uppercase;
                color: var(--text-color);
              }
            }
          }
        }
      }
    }
    div.aboutSection {
      background-color: transparent !important;
      padding: 30px 0px;
      @media all and (max-width: 768px) {
        padding: 30px 30px;
      }
      h1.heading {
        font-size: 25px;
        color: var(--text-color);
        margin-bottom: 20px;
        display: flex;
        inset-inline-start: 0;
      }
      div.aboutLists {
        display: flex;
        flex-wrap: wrap;
        ul{
        flex-direction: column;
        // width: 208px;
      width: 100%;
       div.aboutContainer{
        background-color: transparent;
        width: 100%;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        // width: 208px;
        li.aboutlist {
          width: 100%;
          background-color: var(--white-color);
          margin-inline-end: 7px;
          // margin-bottom: 20px;
          // cursor: pointer;
          
          &:hover {
            div.info {
              h1.name {
                color: var(--grey-color);
              }
            }
          }
          &:nth-child(8n) {
            margin-inline-end: 0;
          }
          &:last-child {
            margin-inline-end: 0 !important;
          }
          @media all and (max-width: 2600px) {
            width: 25%;
            &:nth-child(7n) {
              margin-inline-end: 0;
            }
            &:nth-child(8n) {
              margin-inline-end: 7px;
            }
          }
          @media all and (max-width: 1400px) {
            width: 14%;
            &:nth-child(7n) {
              margin-inline-end: 0;
            }
            &:nth-child(8n) {
              margin-inline-end: 7px;
            }
          }
          @media all and (max-width: 1200px) {
            width: 19%;
            &:nth-child(7n) {
              margin-inline-end: 7px;
            }
            &:nth-child(6n) {
              margin-inline-end: 0;
            }
          }
          @media all and (max-width: 980px) {
            width: 24%;
            &:nth-child(4n) {
              margin-inline-end: 0;
            }
            &:nth-child(6n) {
              margin-inline-end: 7px;
            }
          }
          @media all and (max-width: 768px) {
            width: 32%;
            &:nth-child(4n) {
              margin-inline-end: 0;
            }
            &:nth-child(6n) {
              margin-inline-end: 7px;
            }
          }
          
          @media all and (max-width: 480px) {
            width: 49%;
            &:nth-child(3n) {
              margin-inline-end: 7px;
            }
            &:nth-child(2n) {
              margin-inline-end: 0;
            }
          }
          @media all and (max-width: 360px) {
            width: 99%;
            &:nth-child(3n) {
              margin-inline-end: 7px;
            }
            &:nth-child(2n) {
              margin-inline-end: 0;
            }
          }
         
          div.imageContainer {
            width: 100%;
            background-color: var(--white-color);
            margin-inline-end: 7px;
            // margin-bottom: 20px;
            position: relative;
            align-content: space-around;
            img {
              width: 100%;
              align-items: center;
              display: block;
              
            }
            div.openurl{
              position:absolute;
              z-index: 99;
              margin-top: -44px;
              inset-inline-end: 5px;
              cursor: pointer;
             svg{
              fill: #fff !important;
              width:16px;
              stroke: #fff;
             }
             
            }
           
          }
          div.info {
            padding: 10px 10px 30px;
            background: #fff;
            padding: 8px;
            // min-height: 121px;
            min-height: 70px;
            max-height: 121px;
  
            h1.title {
              font-size: 16px;
              color: var(--text-hover);
            }
            p.description{
  
               color: #666;
              font-weight: 400;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin-top: 10px;
             
            }
          }
        }
      }
    } 
    }
    }
    div.awardsAndFestivals {
      padding: 30px 0px 0;
      background-color: transparent !important;
      align-items: center;
      h1.heading {
        font-size: 25px;
        color: var(--text-color);
        margin-bottom: 20px;
        display: flex;
        inset-inline-start: 0;
      }
      ul.lists {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        li.list {
          margin-inline-end: 10px;
          width: 24%;
          margin-bottom: 20px;
          text-align: center;
          align-items: center;
          &:nth-child(4n) {
            margin-inline-end: 0;
          }
          @media all and (max-width: 1200px) {
            width: 32.6%;
            &:nth-child(3n) {
              margin-inline-end: 0;
            }
            &:nth-child(4n) {
              margin-inline-end: 10px;
            }
          }
          @media all and (max-width: 980px) {
            width: 49%;
            &:nth-child(3n) {
              margin-inline-end: 10px;
            }
            &:nth-child(2n) {
              margin-inline-end: 0;
            }
          }
          @media all and (max-width: 640px) {
            width: 100%;
            &:nth-child(3n) {
              margin-inline-end: 0;
            }
            &:nth-child(2n) {
              margin-inline-end: 0;
            }
            margin-inline-end: 0;
          }
          div.imageContainer {
            margin: 0 auto 20px;
            width: 40px;
            filter: grayscale(100%);
            background: rgb(200, 200, 200);
            margin-bottom: 20px;
            align-items: center;
            img {
              display: block;
              width: 100%;
            }
          }
          h1.title {
            color: var(--text-color);
            font-size: 14px;
            margin-bottom: 10px;
           
          }
          div.descriptionAndYear{
            display: flex;
            justify-content: center;
            span.year{
              margin-inline-end: 6px;
              font-size: 13px;
              color:var(--grey-color);
              font-weight: normal;
            }
            p.description{
              font-size: 13px;
              color:var(--grey-color);
              font-weight: normal;
            }
          }
        }
      }
    }
    div.castAndCrew {
      background-color: transparent !important;
      padding: 30px 0px;
      @media all and (max-width: 768px) {
        padding: 30px 30px;
      }
  
      h1.heading {
        font-size: 25px;
        color: var(--text-color);
        margin-bottom: 20px;
        display: flex;
        inset-inline-start: 0;
      }
      ul.lists {
        display: flex;
        flex-wrap: wrap;
  
        li.list {
          width: 24%;
          background-color: var(--bg-color);
          margin-inline-end: 7px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          
          &:hover {
            div.info {
              h1.name {
                color: var(--text-hover);
              }
            }
          }
          &:nth-child(8n) {
            margin-inline-end: 0;
          }
          &:last-child {
            margin-inline-end: 0 !important;
          }
          @media all and (max-width: 1400px) {
            width: 13.7%;
            &:nth-child(7n) {
              margin-inline-end: 0;
            }
            &:nth-child(8n) {
              margin-inline-end: 7px;
            }
          }
          @media all and (max-width: 1200px) {
            width: 19%;
            &:nth-child(7n) {
              margin-inline-end: 7px;
            }
            &:nth-child(6n) {
              margin-inline-end: 0;
            }
          }
          @media all and (max-width: 980px) {
            width: 24%;
            &:nth-child(4n) {
              margin-inline-end: 0;
            }
            &:nth-child(6n) {
              margin-inline-end: 7px;
            }
          }
          @media all and (max-width: 768px) {
            width: 32.5%;
            margin-bottom: 2%;
            &:nth-child(4n) {
              margin-inline-end: 7px;
            }
            &:nth-child(3n) {
              margin-inline-end: 0px;
            }
          }
          @media all and (max-width: 640px) {
            width: 32%;
            &:nth-child(4n) {
              margin-inline-end: 7px;
            }
            &:nth-child(3n) {
              margin-inline-end: 0px;
            }
          }
          @media all and (max-width: 480px) {
            width: 48%;
            &:nth-child(3n) {
              margin-inline-end: 7px;
            }
            &:nth-child(2n) {
              margin-inline-end: 0;
            }
          }
          @media all and (max-width: 360px) {
            width:99%;
          }
          div.imageContainer {
            // filter: grayscale(100%);
            background: rgb(200, 200, 200);
            width: 100%;
            img {
              width: 100%;
              display: block;
            }
          }
          div.info {
            flex: 1;
            padding: 10px 10px 20px;
            background-color: rgba(203, 203, 208, 0.16);
            h1.name {
              font-size: 14px;
              color: var(--text-color);
              font-weight: 400;
            }
            p.role {
              font-size: 12px;
              color: var(--grey-color);
            }
          }
        }
      }
    }
    div.showTrailerContainer{
      margin-top: 20px;
      h1.heading{
        color: var(--heading-color);
        font-size: 23px;
        font-weight: 500;
        margin-bottom: 15px;
        display: flex;
        inset-inline-start: 0;
      }
      div.imageContainer{
        width: 200px;
        position: relative;
        cursor: pointer;
        &:hover{
                  div.playIconContainer{
                      visibility: visible;
            animation: fadeIn 0.2s ease-in-out forwards;
              }
      }
        img{
          display: block;
          width: 100%;
          border-radius: 3px;
        }
        div.playIconContainer{
          align-items: center;
          border: 1px solid var(--white-color);
          border-radius: 50%;
          background: rgba(30,30,20,.5);;
          cursor: pointer;
          display: flex;
          height: 40px;
          width: 40px;
          justify-content: center;
          left: 50%;
          position: absolute;
          top: 50%;
          -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
          transition: .3s ease-in-out;
          z-index: 9;
          visibility: hidden;
          animation: fadeOut 0.2s ease-in-out forwards;
          svg{
              width: 25px !important;
              height: 25px !important;
          }
      }
      }
    }
    div.similiarShowsContainer{
      margin-top: 40px;
      h1.heading{
        color: var(--heading-color);
        font-size: 23px;
        font-weight: 500;
        margin-bottom: 20px;
        display: flex;
        inset-inline-start: 0;
      }
      div.cards{
        display: flex;
        flex-wrap: wrap;
        div.show{
          width: 31.7%;
          margin-inline-end: 17px;
          margin-bottom: 20px;
          &:last-child{
            margin-inline-end: 0;
          }
          &:nth-child(3n){
            margin-inline-end: 0;
          }
          @media all and (max-width:980px){
            width: 48.7%;
            &:nth-child(3n){
              margin-inline-end: 17px;
            }
            &:nth-child(2n){
              margin-inline-end: 0;
            }
          }
          @media all and (max-width:768px){
            width: 48.4%;
          }
          @media all and (max-width:640px){
            margin-inline-end: 10px;
            &:nth-child(3n){
              margin-inline-end: 10px;
            }
          }
          @media all and (max-width:480px){
            width: 100%;
            margin-inline-end: 0;
            &:nth-child(3n){
              margin-inline-end: 0;
            }
          }
        }
      }
    }
  }
}
