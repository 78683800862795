div.header-container {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 10%,
    transparent
  );
  height: 65px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  &.background {
    background-color: var(--bg-color);
  }
  div.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    div.menuIconContainer{
      display: none;
      @media all and (max-width:768px){
        display: block;
      width: 30%; 

        svg{
          color: var(--text-color);
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
    div.leftContainer {
      display: flex;
      align-items: center;
      width: 50%;
      @media all and (max-width: 1200px) {
        width: 70%;
      }
      @media all and (max-width: 768px) {
        width: 30%;
        justify-content: center;
      }
      
      div.logoContainer {
        margin-inline-end: 40px;
        @media all and (max-width: 768px) {
          margin-inline-end: 0;
        }
        h1 {
          width: 100px;
          // height: 40px;
          max-height: 40px;
          
          @media all and (max-width: 480px) {
            width: 100px;
          }
          @media all and (max-width: 360px) {
            width: 100px;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            aspect-ratio: 3;
            object-fit: contain;
          }
        }
      }
      nav {
        @media all and (max-width:768px){
          display: none;
        }
        ul.navLinks {
          display: flex;
          li.link {
            font-size: 14px;
            margin-inline-end: 18px;
            color: var(--text-color);
            cursor: pointer;
            transition: 0.3s ease-in-out;
            font-weight: 300;
            &:hover{
              color: var(--text-hover);
            }
          }
        }
      }
      ul.hide {
        @media all and (max-width: 1200px) {
          &.hide {
            display: none;
          }
        }
      }
    }

    div.rightContainer {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      justify-content: flex-end;
      width: 30%;
      transition: 0.2s ease-in-out;
      position: relative;
      @media all and (max-width: 1200px) {
        width: 40%;
      }
      @media all and (max-width: 768px) {
        width: 30%;
      }
      
      div.inputContainer {
        position: relative;
        width: 5%;
        margin-inline-end: 20px;
        margin-top: 5px;
        @media all and (max-width:768px){
          display: none;
        }
        input {
          border: none;
          outline: none;
          width: 85%;
          margin-inline-end: auto;
          background-color: transparent;
          font-size: 13px;
          color: var(--grey-color);
          caret-color: var(--grey-color);
          display: none;
          // @media all and (max-width: 1200px) {
          //   display: none;
          // }
          &::placeholder {
            color: #cac9c8;
            font-size: 13px;
            transition: 0.3s ease-in-out;
          }
        }
        &.showInput {
          background-color: var(--black-color);
          padding: 8px 6px;
          display: flex;
          align-items: center;
          width: 70%;
          justify-content: space-between;
          transition: 0.3s ease-in-out;
          cursor: pointer;
          border: 1px solid var(--white-color);
          margin-top: 0;
          input {
            display: block;
          }
          @media all and (max-width:768px){
            display: none;
          }
        }
        @media all and (max-width: 1200px) {
          &.showInput {
            width: 70%;
            input {
              display: block;
            }
          }
        }
        @media all and (max-width: 980px) {
          width: 12%;
          justify-content: center;
          padding: 10px 8px;
          &.showInput {
            width: 57%;
            justify-content: space-between;
          }
        }
        @media all and (max-width: 768px) {
          display: none;
        }
        &:hover {
          border-color: #c5c5c8;
        }
        svg {
          width: 20px !important;
          height: 20px !important;
          color: var(--text-color) !important;
          margin-inline-end: 6px;
          cursor: pointer;
          // @media all and (max-width: 1200px) {
          //   margin-right: 0;
          // }
        }

        div.searchSuggestionsContainer {
          position: absolute;
          top: 47px;
          z-index: 9;
          background-color: var(--transparent-color);

          padding: 15px;
          width: 100%;
          inset-inline-start: 0;
          cursor: default;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

          @media all and (max-width: 768px) {
            display: none;
          }
          
          ul.lists {
            div.categorySection{
              margin-bottom: 20px;
              h6.heading {
                font-size: 12px;
                color: var(--text-color);
                margin-bottom: 10px;
                span.count {
                  font-weight: normal;
                }
              }
              li.list {
                display: flex;
                margin-bottom: 3px;
                cursor: pointer;
                div.listContainer{
                  
                    display: flex;
                    div.left {
                      margin-inline-end: 10px;
                      div.imageContainer {
                        width: 50px;
                        height: 50px;
                        img {
                          width: 100%;
                          height: 100%;
                          display: block;
                          object-fit: cover;
                        }
                      }
                    }
                    div.right {
                      h1.title {
                        font-size: 14px;
                        color: var(--text-color);
      
                      }
                      span.director {
                        font-size: 12px;
                        color: var(--grey-color);
                      }
                    }
                  
                 
                }
               
              }
            }

           

          }
          div.seeAll {
            text-align: center;
            border-top: 1px solid #eaeaea;
            padding-top: 10px;
            margin-top: 20px;
            cursor: pointer;
            button {
              background-color: transparent;
              font-size: 14px;
              color: var(--text-color);
              font-weight: 700;
              cursor: pointer;
            }
          }
        }

        div.closeIcon {
          position: absolute;
          inset-inline-end: 5px;
          svg {
            width: 16px !important;
            height: 16px !important;
          }
        }

        div.searchLoading {
          position: absolute;
          inset-inline-end: 30px;
        }
      }

      div.languageDropdown {
        position: relative;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        color: white;
        // padding: 15px 0;
        padding: 13px 0;
        padding-inline-end: 26px;
        // transition: color 0.3s ease, font-weight 0.3s ease;
        @media(max-width: 768px){
          display: none;
        }
      
        &:hover {
          // color: var(--text-hover);
          color: gray;
        }
      
        div.language {
          font-size: 14px;
          // font-weight: bold;
          // font-weight: normal;
          transition: color 0.3s ease, font-weight 0.3s ease;
        }
      
        div.languageOptions {
          position: absolute;
          top: 100%;
          inset-inline-start: 0;
          min-width: 210px;
          // background-color: var(--bg-color);
          background-color: var(--transparent-color);
          padding-top: 15px;
          padding-bottom: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 9999;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 4px; 
            background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
            // background: linear-gradient(281deg, var(--button-color), var(--button-hover));
          }
      
          div.optionsList {
            display: flex;
            flex-direction: column;
      
            div.languageOption {
              font-size: 12px;
              padding: 8px 12px;
              color: var(--text-color);
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              white-space: nowrap;

              &[data-selected="true"] {
                color: var(--gradient-left-color);
                // color: var(--button-color);
                
                &:after {
                  content: "✔";
                  margin-inline-start: auto;
                }
              }
      
              &:hover {
                color: var(--gradient-left-color);
                // color: gray;
              }
            }
          }
        }
      }

      ul.browse {
        border-inline-end: 1px solid var(--border-color);
        padding: 13px 0;
        li {
          cursor: pointer;
          padding: 0 30px;
          &:hover {
            span {
              color: var(--text-hover);
            }
          }
          &.active {
            span {
              color: var(--text-hover);
            }
          }
          span {
            font-size: 12px;
            font-weight: 900;
            transition: 0.3s ease-in-out;
            color: var(--text-color);
          }
        }
      }
      div.userIcon {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
       
        &:hover {
          padding-bottom: 20px;
          padding-top: 20px;
          svg {
            transform: rotate(180deg);
          }
          div.menuListContainer {
            display: block;
            animation: fadeIn 0.2s ease-in-out;
          }
        }
        div.imageContainer {
          width: 28px;
          margin-inline-end: 2px;
          img {
            width: 100%;
            display: block;
            border-radius: 5px;
          }
        }
        &.noActive{
          svg{
            transform: rotate(0deg);
          }
        }
        div.downArrow {
          line-height: 0;
          svg {
            width: 20px !important;
            height: 20px !important;
            color: var(--text-color);
            transition: 0.3s ease-in-out;
          }
        }
        div.menuListContainer {
          width: 17rem;
          position: absolute;
          inset-inline-end: 0;
          top: 56px;
          padding: 10px 0 0;
          background-color: var(--transparent-color);
          // display: none;
          border: 1px solid var(--border-color);
          line-height: 0;
          margin-inline-start: 20px;
          animation: fadeOut 0.3s ease-in-out;
          display: none;
          &.noActive{
            display: none;
          }
          div.arrow {
            position: absolute;
            top: -18px;
            inset-inline-end: 20px;
            svg {
              width: 25px !important;
              height: 25px !important;
              transform: rotate(180deg);
            }
          }
          svg {
            width: 18px !important;
            height: 18px !important;
            color: var(--text-color);
            cursor: pointer;
          }
          ul {
            li {
              cursor: pointer;
              padding: 8px 10px;
              display: flex;
              align-items: center;
              &.logOut,&.logIn {
                padding: 20px 15px;
                border-top: 1px solid var(--border-color);
                justify-content: center;
              }
              div.icon {
                width: 18px;
                img {
                  width: 100%;
                }
              }
              div.icon {
                margin-inline-end: 10px;
              }
              span {
                font-size: 12px;
                color: var(--text-color);
              }
              &:hover {
                span {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      h4 {
        color: var(--text-color);
        font-size: 12px;
        margin-inline-start: 10px;
      }

      div.buttonContainer {
        margin-inline-start: 30px;
        @media all and (max-width: 480px) {
          margin-inline-start: 15px;
        }
        @media all and (max-width: 360px) {
          margin-inline-start: 7px;
        }
        button.signIn {
          background-color: transparent;
          font-weight: 700;
          font-size: 12px;
          color: var(--text-color);
          cursor: pointer;
          @media all and (max-width: 360px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
div.userInfoContainer {
  position: fixed;
  inset-inline-end: 30px;
  width: 20rem;
  top: 15%;
  background-color: rgb(37, 42, 52);
  border-radius: 8px;
  display: none;
  z-index: 9999;
  &.active {
    display: block;
  }
  @media all and (max-width: 1200px) {
    top: 15%;
  }
  @media all and (max-width: 640px) {
    width: 84%;
  }
  div.top {
    padding: 20px 20px 0;

    ul {
      li {
        border-bottom: 1px solid var(--border-color);
        padding: 15px 0;
        cursor: pointer;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        span {
          color: var(--white-color);
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
  }
  div.bottom {
    background: linear-gradient(180deg, #10141f, rgba(16, 20, 31, 0));
    padding: 15px;
    div.signOutContainer {
      display: flex;
      align-items: center;
      padding: 12px;
      background-color: var(--border-color);
      border-radius: 8px;
      cursor: pointer;
      svg {
        color: var(--white-color);
        width: 22px !important;
        height: 22px !important;
        margin-inline-end: 10px;
      }
      span {
        color: var(--white-color);
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}
div.overlayOne {
  &.active {
    @media all and (max-width: 768px) {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--overlay);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.3s ease-in-out;
    }
  }
}

div.overlayOne.active {
  display: flex;
}
div.browseMore {
  @media all and (max-width: 768px) {
    display: none;
  }
  //  animation: fadeIn 0.3s ease-in-out ;
  display: none;

  div.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.browseMoreContainer {
    animation: fadeIn 0.7s ease-in-out;

    position: fixed;
    // width: 70%;
    top: 68px;
    inset-inline-start: 8%;
    // transform: translateX(-50%);
    background-color: var(--transparent-color);
    display: flex;
    border-radius: 6px;
    z-index: 9999;
    div.arrow {
      position: absolute;
      top: -18px;
      inset-inline-end: 40px;
      @media all and (max-width:2500px){
        inset-inline-end: 70px;
      }
      @media all and (max-width:1600px){
        inset-inline-end: 60px;
      }
      @media all and (max-width:1200px){
        inset-inline-end: 50px;
      }
      svg {
        width: 25px !important;
        height: 25px !important;
        transform: rotate(180deg);
        color: var(--text-color);
      }
    }
    // &::after {
    //   content: "";
    //   top: -10px;
    // //  width: 7px;
    //  height: 4px;
    //   border-color: transparent transparent var(--white-color);
    //   border-style: solid;
    //   border-width: 0px 9px 15px;
    //   position: absolute;
    //   right: 40px;
    //   @media all and (max-width: 1768px) {
    //     right: 60px;
    //   }
    //   @media all and (max-width: 1600px) {
    //     right: 44px;
    //   }
    // }
    @media all and (max-width: 2500px) {
      top: 68px;
      inset-inline-start: 6%;
    }
    
    
    @media all and (max-width: 1400px) {
      top: 68px;
    }

    h1.categoryName {
      font-size: 14px;
      margin-bottom: 20px;
      color: var(--grey-color);
    }
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      li {
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          span {
            color: var(--text-hover);
          }
        }
        span {
          font-size: 14px;
          color: var(--text-color);
          transition: 0.3s ease-in-out;
        }
      }
    }
    div.left {
      // width: 22%;
      padding: 30px 25px;
      overflow-y: scroll;
      height: 500px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(125, 128, 128);
      }
    }
    div.center {
      width: 40%;
      background-color: #f0f3f5;
      padding: 30px 25px;
      div.sections {
        display: flex;
        justify-content: space-between;
        div.leftSection {
          width: 50%;
        }
        div.rightSection {
          width: 50%;
        }
      }
    }
    div.right {
      width: 40%;
      padding: 30px 25px;
    }
  }
}
div.browseMore.active {
  display: block;
  @media all and (max-width: 768px) {
    display: none;
  }
  // animation: fadeIn 0.4s ease-in-out ;
}

div.browseMoreResponsive {
  div.searchArea {
    display: none;
    z-index: 9999999;
    position: fixed;
    width: 50%;
    background: var(--transparent-color);
    padding: 15px;
    top: 0;
    inset-inline-start: -100%;
    border-bottom: 1px solid var(--border-color);
    transition: 0.3s ease-in-out;

    @media all and (max-width: 768px) {
      &.active {
        inset-inline-start: 0;

      }
      display: block;
      div.inputContainer {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        position: relative;

        input {
          display: block;
          outline: none;
          border: none;
          background-color: transparent;
          width: 93%;
          color: var(--text-color);
          font-size: 16px;
        }
        svg {
          margin-inline-end: 10px;
        }
      }
    }
    @media all and (max-width: 640px) {
      width: 60%;
    }
    @media all and (max-width: 480px) {
      width: 75%;
    }
    div.closeIcon {
      position: absolute;
      inset-inline-end: 5px;
      top: 30%;
      display: none;
      @media all and (max-width: 768px) {
        display: block;
      }
      svg {
        width: 16px !important;
        height: 16px !important;
        color: var(--text-color);

      }
    }

    div.searchLoading {
      position: absolute;
      inset-inline-end: 30px;
      bottom: 30px;
      display: none;
      @media all and (max-width: 768px) {
        display: block;
      }
    }
    div.searchSuggestionsContainerMedium {
      position: absolute;
      top: 47px;
      z-index: 99999;
      background-color: var(--transparent-color);

      padding: 15px;
      width: 100%;
      inset-inline-start: 0;
      cursor: default;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: none;
      @media all and (max-width: 768px) {
        display: block;
      }
     
      h6.heading {
        font-size: 12px;
        color: var(--grey-color);
        margin-bottom: 10px;
        span.count {
          font-weight: normal;
        }
      }
      ul.lists {
        li.list {
          display: flex;
          margin-bottom: 3px;
          cursor: pointer;
          div.left {
            margin-inline-end: 10px;
            div.imageContainer {
              width: 50px;
              height: 50px;
              img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
              }
            }
          }
          div.right {
            h1.title {
              font-size: 13px;
              color: var(--text-color);
            }
            span.director {
              font-size: 12px;
              color: var(--grey-color);
            }
          }
        }
      }
      div.seeAll {
        text-align: center;
        border-top: 1px solid var(--border-color);
        padding: 10px 0;
        margin-top: 20px;
        cursor: pointer;
        background-color: var(--transparent-color);
        button {
          background-color: transparent;
          font-size: 14px;
          color: var(--text-color);
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }
  div.container {
    position: fixed;
    inset-inline-start: -100%;
    width: 35%;
    background: var(--transparent-color);
    padding: 70px 30px 35px;
    z-index: 999999;
    top: 0;
    bottom: 0;
    overflow: scroll;
    transition: 0.3s ease-in-out;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media all and (max-width: 768px) {
      &.active {
        inset-inline-start: 0;
      }
    }
    @media all and (max-width: 980px) {
      width: 45%;
    }
    @media all and (max-width: 768px) {
      width: 50%;
      inset-inline-start: -100%;
    }
    @media all and (max-width: 640px) {
      width: 60%;
    }
    @media all and (max-width: 480px) {
      width: 75%;
    }
    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div.left {
        h1 {
          color: var(--text-hover);
          font-size: 15px;
          font-weight: 700;
        }
      }
      div.right {
        svg {
          color: var(--text-color);
          width: 23px !important;
          height: 23px !important;
        }
      }
    }
    ul.nav{
      margin-top: 20px;
      li{
        color: var(--text-color);
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
    div.top {
      margin-top: 50px;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--border-color);

      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: var(--text-color);
          }
          &:hover {
            span {
              color: var(--text-hover);
            }
          }
        }
      }
    }
    div.center {
      padding: 30px 0;
      border-bottom: 1px solid var(--border-color);
      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: var(--text-color);
          }
        }
      }
    }
    div.bottom {
      padding: 30px 0;
      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
    div.languageDropdown {
      position: relative;
      cursor: pointer;
      display: inline-block;
      font-size: 15px;
      color: white;
      padding: 15px 0;
      // transition: color 0.3s ease, font-weight 0.3s ease;
    
      &:hover {
        // color: var(--text-hover);
        color: gray;
      }
    
      h1.language {
        font-size: 15px;
        font-weight: bold;
        transition: color 0.3s ease, font-weight 0.3s ease;
      }
    
      div.languageOptions {
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        min-width: 210px;
        // background-color: var(--bg-color);
        background: var(--transparent-color);
        padding-top: 15px;
        padding-bottom: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 9999;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          width: 100%;
          height: 4px; 
          background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
          // background: linear-gradient(281deg, var(--button-color), var(--button-hover));
        }
    
        div.optionsList {
          display: flex;
          flex-direction: column;
    
          div.languageOption {
            font-size: 14px;
            padding: 8px 12px;
            color: var(--text-color);
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;

            &[data-selected="true"] {
              color: var(--gradient-left-color);
              // color: var(--button-color);
              
              &:after {
                content: "✔";
                margin-inline-start: auto;
              }
            }
    
            &:hover {
              color: var(--gradient-left-color);
              // color: gray;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
