header.accountsPageHeader{
    height: 100px;
    position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
    margin: 0 auto;
    
        
        div.left{
            div.logoContainer{
                // h1{
                //     width: 120px;
                //     @media all and (max-width:480px){
                //       width: 72px;
                //     }
                //     @media all and (max-width:360px){
                //       width: 72px;
                //     }
                //     img {
                //       display: block;
                //       width: 100%;
                //     }
                // }
                // margin-inline-end: 40px;
                // @media all and (max-width: 768px) {
                //   margin-inline-end: 0;
                // }
                h1 {
                  width: 150px;
                  max-height: 40px;
                  
                  @media all and (max-width: 480px) {
                    width: 100px;
                  }
                  @media all and (max-width: 360px) {
                    width: 100px;
                  }
                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 3;
                    object-fit: contain;
                  }
                }
            }
        }
        div.right{
            button{
                background: transparent;
                color: var(--white-color);
                font-size: 15px;
                font-weight: 600;
                cursor: pointer;
            }
        }
}