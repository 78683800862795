div.LayoutsContainer{
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    div.routesContainer{
        flex: 1 0 auto;
        background-color: var(--bg-color);
    }

    div.resubscriptionInfo {
        display: flex;
        align-items: center;
        z-index: 9999;
        width: 100%;
        height: auto;
        position: fixed;
        bottom: 0;
        background: #fff;


        .resubscribeMessage {
            width: 85%;
            background: #fff;
            color: red;
            padding: 5px;
            text-align: center;
            font-weight: 600;
            margin: 5px;
        }

        .resubscribeButton {
            width: 15%;
            background-color: #000;
            color: #fff;
            padding: 5px;
            text-align: center;
            cursor: pointer;
            font-weight: 600;
            margin: 2px;
            transition: background-color 0.3s ease, filter 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                filter: brightness(2.0);
            }
        }
        @media all and (max-width: 768px) {
            .resubscribeMessage {
                font-size: 10px;
                width: 100%;
            }
            .resubscribeButton {
                width: 40%;
                font-size: small;
            }
          }
          @media all and (max-width: 640px) {
            flex-direction: column;
            .resubscribeButton {
                width: 85%;
            }
          }
    }
}